import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  @Input() story = false; // only used for storybook stories
  @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() header: 'simple' | 'custom' = 'simple';
  @Input() headerText = 'This is the Heading';
  @Input() closeIcon = false;
  @Input() footer: 'none' | 'simple' = 'simple';
  @Input() footerLinkText = 'Cancel';
  @Input() footerButtonText = 'Submit';
  @Input() footerLinkFlag: unknown = false;
  @Input() footerButtonFlag: unknown = true;
  @Output() modalClickedFlag = new EventEmitter();

  @ViewChild('modalRef') modal!: TemplateRef<unknown>;
  isNonStaticModel = false;

  constructor(private modalService: NgbModal) {}

  open() {
    this.isNonStaticModel = false;
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(this.modal, {
      size: this.size, // define width of modal
      backdrop: 'static', // don't let modal be closed by clicking outside it
      keyboard: false, // don't let modal be closed via Esc key
    });

    this.openModalSetFocus();

    return modalRef.result.then(
      (result) => {
        this.modalClickedFlag.emit(result);
        return result;
      },
      (reason) => reason
    );
  }

  openModalSetFocus() {
    setTimeout(() => {
      const modalElement = document.querySelector(
        '.modal-dialog'
      ) as HTMLElement;

      if (modalElement) {
        modalElement.setAttribute('tabindex', '-1');
        modalElement.focus();
      }
    }, 0);
  }

  openNonStaticModal() {
    this.isNonStaticModel = true;
    this.modalService.dismissAll();
    return this.modalService
      .open(this.modal, {
        size: this.size, // define width of modal
      })
      .result.then(
        (result) => {
          this.modalClickedFlag.emit(result);
          return result;
        },
        (reason) => reason
      );
  }
}
